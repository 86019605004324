import { Injectable, inject } from '@angular/core';
import { AuthRepository } from '../data/repositories/auth.repository';
import { tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User } from '../data/types/user.interface';
import { SupabaseService } from '../supabase.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  #sb = inject(SupabaseService);
  #user$ = this.#sb.user$;
  constructor(private repo: AuthRepository) {
    this.#init();
  }

  login(email: string, password?: string) {
    return this.#sb.signInWithEmail(email, password);
  }

  async signInWithIdToken(credential: string, provider = 'google') {
    await this.#sb.signInWithIdToken({
      provider,
      token: credential,
    });
  }

  logout() {
    return this.#sb.signout();
  }

  register(email: string, password: string) {
    return this.#sb.signUp(email, password);
  }

  isLoggedIn() {
    return this.#sb.isAuthenticated();
  }

  isLoggedIn$() {
    return this.#sb.isAuthenticated$();
  }

  #init() {
    this.#user$
      .pipe(
        untilDestroyed(this),
        tap((u) => this.#setUser(u))
      )
      .subscribe();
  }

  #setUser(user: User | null) {
    this.repo.setUser(user);
  }

  get user$() {
    return this.#user$;
  }

  get user() {
    return this.#sb.user;
  }
}
